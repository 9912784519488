import { useUserContext } from '../contexts/UserContext';
import { Types, Queries } from '../';

const useIsUserBookedEvent = (ubId?: string | null) => {
	const { user, isLoading: userLoading } = useUserContext();
	const { data: bookingData, loading: bookingLoading } = Queries.useBookingsQuery({
		variables: {
			searchQuery: {
				filters: [
					{
						field: 'userId',
						operation: Types.SearchFilterOperation.MatchPhrase,
						value: [user?.id ?? '']
					},
					{
						field: 'eventUbEventId',
						operation: Types.SearchFilterOperation.MatchPhrase,
						value: [ubId ?? '']
					}
				]
			}
		},
		skip: !user?.id || !ubId
	});

	const isEventBooked = Boolean(
		// TODO this condition needs to be changed, we should not rely on ub Id for this check
		bookingData?.bookings?.data?.some((each) => each.event?.ubEventId === ubId)
	);

	return {
		loading: userLoading || bookingLoading,
		isEventBooked
	};
};

export default useIsUserBookedEvent;
