import { DateFormat, parseDBDate } from 'components';
import { ConfigType, Dayjs, isDayjs } from 'dayjs';
import { dayjsWithTimezone } from '../utils';

const formatDate = (
	dateString: ConfigType,
	outputFormat: DateFormat = DateFormat.dateTime,
	parseFormat?: DateFormat
): string => {
	const dayjsDate: Dayjs = isDayjs(dateString)
		? dayjsWithTimezone(dateString)
		: dayjsWithTimezone(parseDBDate(dateString, parseFormat));
	if (dayjsDate.isValid()) {
		return dayjsDate.format(outputFormat);
	}
	return 'Invalid Date';
};

export default formatDate;
