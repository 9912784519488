import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		width: '100%',
		height: theme.headerHeight.lg,
		backgroundColor: theme.palette.common.white,
		display: 'flex',
		alignItems: 'center',
		padding: '0 16px'
	},
	firstRow: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%'
	},
	navLinks: {
		display: 'flex',
		color: theme.palette.grey[500]
	},
	navItem: {
		margin: theme.spacing(0, 2)
	},
	partnerforum: {
		color: '#00000061'
	},
	logoContainer: {
		display: 'flex',
		marginRight: theme.spacing(2),
		color: theme.palette.primary.main
	},
	logo: {
		width: '24px',
		height: '24px',
		marginRight: '8px',
		color: theme.palette.primary.main
	},
	localesContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(1)
	}
}));
