import { initializeApollo, Types } from 'common';
import { GetCompanyConfiguration } from './queries';
import { GetCompanyConfigurationQuery } from './queries.generated';
import getContentById from '../queries/getContentById';
import { ParsedUrlQuery } from 'querystring';

/**
 * Object containing the tenants having a custom version of the platform, where the key is the
 * tenant slug and the value is the tenant identifier.
 */
export const companyTenantsIds: Array<Types.UUID> = ['696c6930-cea5-4b42-a970-16b67117f533'];

export type TenantConfig = GetCompanyConfigurationQuery['company'];

export const getTenantsConfiguration = async (): Promise<Array<TenantConfig>> => {
	const apolloClient = initializeApollo();

	const configurations = await Promise.all(
		companyTenantsIds.map(async (tenantId) => {
			const { data } = await apolloClient.query({
				query: GetCompanyConfiguration,
				variables: {
					companyId: tenantId
				}
			});
			return data.company;
		})
	);

	return configurations;
};

export const getTenantConfigFromPathname = async (
	tenantsConfig: Array<TenantConfig>,
	pathname: string,
	query: ParsedUrlQuery
): Promise<TenantConfig | null> => {
	if (pathname.includes('/content/') && typeof query?.id === 'string' && query.id != null) {
		const { content } = await getContentById(query.id);
		return tenantsConfig.find((config) => content?.provider?.id === config.id) || null;
	} else if (pathname.includes('/partner-fortbildungsforum/')) {
		return (
			tenantsConfig.find((config) => pathname.includes(config.companyPage?.slug || '')) || null
		);
	} else if (
		[
			'/user/login',
			'/user/registration',
			'/user/complete-profile',
			'/user/forgot-password',
			'/user/reset-password'
		].some((path) => pathname.includes(path))
	) {
		// Split the pathname into path and search parts
		const [, searchString] = pathname.split('?');
		if (!searchString) {
			return null;
		}

		const searchParams = new URLSearchParams(searchString);
		const tenant = searchParams.get('tenant');
		return tenantsConfig.find((config) => tenant === config.companyPage?.slug) || null;
	}
	return null;
};
