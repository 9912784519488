import { getLanguageFromLocale } from './locale';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import tz from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import businessTime from 'dayjs-business-time';
import { LOCALE_TO_TIMEZONE } from '../constants';

const setupDayjs = (locale: Locale) => {
	require('dayjs/locale/en');
	require('dayjs/locale/de');
	require('dayjs/locale/fr');
	dayjs.extend(tz);
	const language = getLanguageFromLocale(locale);
	dayjs.locale(language);

	dayjs.extend(relativeTime);
	dayjs.extend(utc);
	dayjs.extend(isSameOrAfter);
	dayjs.extend(isSameOrBefore);
	const defaultTimeZone = LOCALE_TO_TIMEZONE[locale];
	dayjs.tz.setDefault(defaultTimeZone);
	dayjs.extend(duration);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	dayjs.extend(businessTime);
};

export default setupDayjs;
