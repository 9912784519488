/**
 * @param replacementQuery - query params string without initial `?`
 */
const getCurrentUrlWithoutQuery = (replacementQuery = ''): string => {
	if (typeof window === 'undefined') {
		return '';
	}
	replacementQuery = replacementQuery ? `?${replacementQuery}` : '';
	const { href, search } = window.location;
	const result = href.replace(search, replacementQuery);
	return result;
};

export default getCurrentUrlWithoutQuery;
