import Dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';
import {
	Types,
	Fragments,
	getCountryCode,
	formatters,
	SponsorshipContent,
	ContentTransformers
} from 'common';
import { DateFormat, IconName } from 'components';

import { ContentDocumentsExtendedQuery } from '../graphql/catalog/queries';
import { IntlShape } from 'react-intl';
import { ProgramScheduleSimplified } from './Content.utils';

export type ContentDocumentFields =
	ContentDocumentsExtendedQuery['contentDocuments']['data'][number];

type IsLiveProps = Pick<Types.Content, 'startDateTime' | 'endDateTime'>;

const isLive = ({ startDateTime, endDateTime }: IsLiveProps) => {
	const startDate = Dayjs(startDateTime);
	const endDate = Dayjs(endDateTime);
	return startDate.isBefore(Dayjs()) && endDate.isAfter(Dayjs());
};

type IsInTheFutureProps = Pick<Types.Content, 'startDateTime'>;

const isInTheFuture = ({ startDateTime }: IsInTheFutureProps) => {
	const startDate = Dayjs(startDateTime);
	return !startDate.isBefore(Dayjs());
};

const generateCreditString = (
	credit: Fragments.ContentWithProgramSchedulesFieldsFragment['credits'][number],
	locale: Locale
) => {
	// show nothing if amount is `0` but proceed for `0 DMP` case
	if (!credit.amount && credit.unit !== 'DMP') {
		return null;
	}
	const countryCode = getCountryCode(locale);
	let creditString = '';
	if (countryCode !== 'ch') {
		creditString = `${credit.amount} ${credit.unit}${!credit.isApproved ? '*' : ''}`;

		// replace `0 DMP` with `DMP-Update`
		creditString = creditString.replace(/^(0 DMP)/, 'DMP-Update');
	} else {
		if (!credit.isApproved && credit.unit === 'SGUM') {
			return null;
		}
		creditString = `${credit.amount} ${credit.isApproved ? credit.unit : 'h'}`;
	}

	return creditString;
};

const filterOutDuplicatesFromCredits = (
	credits: Fragments.ContentDocumentSearchFieldsFragment['credits']
) => {
	// Remove duplicates by unit. Keep credit item with the biggest amount of credits and isApproved: true (if applicable) for unit
	return credits.reduce<typeof credits>((acc, item) => {
		const existingCreditIndex = acc.findIndex((accItem) => accItem.unit === item.unit);
		const existingCredit = acc[existingCreditIndex];

		if (!existingCredit) {
			acc.push(item);
			return acc;
		} else if (
			item.amount > existingCredit.amount ||
			(item.isApproved && !existingCredit.isApproved)
		) {
			acc[existingCreditIndex] = item;
			return acc;
		} else {
			return acc;
		}
	}, []);
};

const processUnApprovedChCredits = (
	items: Fragments.ContentDocumentSearchFieldsFragment['credits']
) => {
	let firstUnapprovedUnit: string | null = null;

	for (let i = 0; i < items.length; i++) {
		if (!items[i]?.isApproved) {
			firstUnapprovedUnit = items[i]?.unit ?? null;
			break;
		}
	}

	if (!firstUnapprovedUnit) {
		return items;
	}

	const highestAmount = Math.max(
		...items
			.filter((item) => !item.isApproved && item.unit === firstUnapprovedUnit)
			.map((item) => item.amount)
	);

	return items.filter((item) => {
		if (item.isApproved) {
			return true; // Keep all approved items
		}

		return item.unit === firstUnapprovedUnit && item.amount === highestAmount;
	});
};

const filterCredits = (
	credits: Fragments.ContentDocumentSearchFieldsFragment['credits'],
	locale: Locale
) => {
	const countryCode = getCountryCode(locale);
	if (countryCode !== 'ch') {
		return filterOutDuplicatesFromCredits(credits);
	}

	/* 
        for switzerland
        In case content has more than one credit with `credits.isApproved = false`
        we will filter them and will just show the one with a bigger amount in the generated string
        example 1: [{ amount 8, isApproved: false, unit: 'CME'}, { amount 16, isApproved: false, unit: 'SGK'}]
        return should be [{ amount: 8, isApproved: false, unit: 'CME' }]
        and generated string should be '8 h'
        example 2:
        [
            { amount 8, isApproved: false, unit: 'CME'}, 
            { amount 16, isApproved: true, unit: 'SGK'},
            { amount 32, isApproved: false, unit: 'SGAIM'}
        ]
        return should be [{ amount: 8, isApproved: false, unit: 'CME' }, { amount: 16, isApproved: true, unit: 'SGK' }]
        and generated string should be '8 h | 16 SGK'
    */

	const sortedCredits = processUnApprovedChCredits(credits);
	return filterOutDuplicatesFromCredits(sortedCredits);
};

const ContentCreditsSeparator = ' | ';
const generateCreditsString = (
	credits: Fragments.ContentDocumentSearchFieldsFragment['credits'],
	locale: Locale,
	numberOfCredits?: number
) => {
	if (!credits.length) {
		return '';
	}

	const result = filterCredits(credits, locale)
		.reduce<Array<string>>((arr, each, index) => {
			if (numberOfCredits && index >= numberOfCredits) {
				return arr;
			}
			const creditString = generateCreditString(each, locale);
			if (!creditString) {
				return arr;
			}
			return [...arr, creditString];
		}, [])
		.join(ContentCreditsSeparator);

	return result;
};

type Nullable<T> = T | null;
type GetContentLocaleProps = Nullable<Pick<Types.Content, 'locale'>> & {
	parent?: Nullable<Pick<Types.Content, 'locale'>>;
};

const getContentLocale = (content?: GetContentLocaleProps): Locale | null => {
	const locale = content?.locale ?? content?.parent?.locale;
	if (locale) {
		return locale;
	}
	return null;
};

type GetParticipationOptionIconNameProps = Pick<ContentDocumentFields, 'participationOption'>;

const getParticipationOptionIconName = (content: GetParticipationOptionIconNameProps): IconName => {
	switch (content.participationOption) {
		case Types.ContentParticipationOption.Hybrid:
			return 'hybrid';
		case Types.ContentParticipationOption.OnSite:
			return 'onsite';
		case Types.ContentParticipationOption.LiveStream:
		default:
			return 'livestream';
	}
};

const getParticipationOptionIconURL = ({
	participationOption
}: GetParticipationOptionIconNameProps): string => {
	switch (participationOption) {
		case Types.ContentParticipationOption.Hybrid:
			return 'https://storage.googleapis.com/bk-public-prod/public/static/icon-event-hybrid.svg';
		case Types.ContentParticipationOption.OnSite:
			return 'https://storage.googleapis.com/bk-public-prod/public/static/icon-event-onsite.svg';
		case Types.ContentParticipationOption.LiveStream:
		default:
			return 'https://storage.googleapis.com/bk-public-prod/public/static/icon-event-livestream.svg';
	}
};

const getDescriptionFields = (
	{
		description,
		productDescription,
		descriptionTitle,
		productDescriptionTitle
	}: {
		description?: Types.Scalars['JSON'];
		productDescription?: Types.Scalars['JSON'];
		descriptionTitle?: Types.Scalars['JSON'];
		productDescriptionTitle?: Types.Scalars['JSON'];
	},
	locale: Locale
) => {
	const descriptionText =
		formatters.formatTranslation(description, { locale }) ??
		formatters.formatTranslation(productDescription, { locale });

	const descriptionTitleText =
		formatters.formatTranslation(descriptionTitle, { locale }) ??
		formatters.formatTranslation(productDescriptionTitle, { locale });

	return { description: descriptionText, descriptionTitle: descriptionTitleText };
};

const getBenefits = (content?: Fragments.ContentWithProgramSchedulesFieldsFragment) => {
	const benefitFilter = (benefit: Fragments.BenefitFieldsFragment) =>
		benefit.usage &&
		(
			[Types.BenefitUsage.Both, Types.BenefitUsage.Content] as Array<Types.BenefitUsage>
		).includes(benefit.usage);

	let benefits = content?.benefits?.filter(benefitFilter);

	if (!benefits || !benefits.length) {
		benefits = content?.product?.benefits?.filter(benefitFilter);
	}

	return benefits;
};

const getTargetGroups = (
	locale: Locale,
	content?: Fragments.ContentWithProgramSchedulesFieldsFragment
) => {
	let targetGroups = content?.targetGroups;
	if (!targetGroups || !targetGroups.length) {
		targetGroups = content?.product?.targetGroups;
	}
	return targetGroups?.filter((targetGroup) =>
		formatters.formatTranslation(targetGroup.title, { locale })
	);
};

const getSpeakers = (programSchedules: Array<ProgramScheduleSimplified>) =>
	uniqBy(
		programSchedules
			.filter((each) => each.speakers)
			.map((each) => each.speakers)
			.flat(),
		'id'
	) as Array<Fragments.SpeakerFieldsFragment>;

const isOnSite = (participationOption: Types.ContentParticipationOption | null | undefined) =>
	participationOption === Types.ContentParticipationOption.Hybrid ||
	participationOption === Types.ContentParticipationOption.OnSite;

const isLivestream = (participationOption?: Types.ContentParticipationOption | null | undefined) =>
	participationOption === Types.ContentParticipationOption.Hybrid ||
	participationOption === Types.ContentParticipationOption.LiveStream;

const getSymposiumDescription = (
	content: SponsorshipContent | Fragments.ContentDocumentSearchFieldsFragment,
	intl: IntlShape
) => {
	const { locale: intlLocale, messages } = intl;
	const contentLocale = getContentLocale(content);
	const locale = contentLocale ?? intlLocale;

	const symposiumProductName = formatters.formatTranslation(content?.product?.name, {
		locale
	});
	const symposiymBrand = formatters.formatEnum(content.brand, {
		messages,
		options: ContentTransformers.brand
	});
	const symposiumCity = formatters.formatTranslation(content.city, { locale });
	const symposiumDate = formatters.formatDate(content.startDateTime, DateFormat.monthAndYear);
	const isWebup =
		content.brand === Types.ProductBrand.Wuex || content.brand === Types.ProductBrand.Wuif;
	const symposiumDescription =
		'contentTitle' in content && isWebup
			? `${symposiumProductName} ${symposiymBrand} ${content.contentTitle}`
			: `${symposiumProductName} ${symposiymBrand} ${symposiumCity} ${symposiumDate}`;

	return symposiumDescription;
};

const isOnDemandAvailable = (
	{
		contentType,
		willBeOnDemand,
		endDateTime,
		videoUrls,
		startDateTime
	}: {
		contentType: Types.ContentType | null | undefined;
		willBeOnDemand: boolean | null | undefined;
		startDateTime: Types.Scalars['DateTime'] | null | undefined;
		endDateTime: Types.Scalars['DateTime'] | null | undefined;
		videoUrls: Array<string | null | undefined> | null | undefined;
	},
	isMember: boolean
): boolean => {
	const now = Dayjs();

	const onDemandAvailableMonths =
		contentType === Types.ContentType.Course && !isMember
			? 6
			: contentType === Types.ContentType.Webinar || isMember
				? 14
				: null;

	// Available On Demand from the first day of the event (if willBeOnDemand=true) and:
	// - visible for 6 months if COURSE and user is not a member
	// - visible for 14 months if WEBINAR or user is a member
	const showOnDemandButton = willBeOnDemand
		? !!onDemandAvailableMonths &&
			now.diff(startDateTime, 'day') >= 0 &&
			now.diff(endDateTime, 'month') <= onDemandAvailableMonths
		: false;

	const someVideoAvailable = !!videoUrls?.some((url) => !!url);
	return showOnDemandButton && someVideoAvailable;
};

const isVideoContent = (content: Pick<Fragments.ContentFieldsFragment, 'contentType'>) =>
	content.contentType === Types.ContentType.Lecture;

const isDocumentContent = (content: Pick<Fragments.ContentFieldsFragment, 'contentType'>) =>
	content.contentType === Types.ContentType.Pdf;

export default {
	isLive,
	getParticipationOptionIconName,
	getParticipationOptionIconURL,
	isInTheFuture,
	generateCreditString,
	ContentCreditsSeparator,
	generateCreditsString,
	getContentLocale,
	getDescriptionFields,
	getBenefits,
	getTargetGroups,
	getSpeakers,
	isOnSite,
	isLivestream,
	getSymposiumDescription,
	isOnDemandAvailable,
	isVideoContent,
	isDocumentContent
};
