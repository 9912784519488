import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import _once from 'lodash/once';

import { useRouter } from '../';
import getStateByNonce from '../cookies/getStateByNonce';

type FallbackURLArgs = {
	url: string;
	destinationPackage?: unknown;
	createAbsolute?: boolean;
};

const redirect = _once(
	(nonce: string | Array<string> | null | undefined, fallbackURLArgs?: FallbackURLArgs) => {
		const redirectState =
			nonce && !Array.isArray(nonce) && nonce.length > 0 ? getStateByNonce(nonce) : null;
		if (redirectState?.redirectTo) {
			window.location.href = redirectState?.redirectTo;
		} else if (fallbackURLArgs) {
			const { url } = fallbackURLArgs;
			window.location.href = url;
		} else {
			window.location.href = '/';
		}
	}
);

/**
 * Reads the "state" query parameter from the URL, checks if there is some information associated
 * to the corresponding nonce and performs the redirection if the state has some "redirectTo" field
 * or if the fallbackURLArgs argument is provided.
 */
const useUrlState = (props: {
	setIsLoading?: Dispatch<SetStateAction<boolean>>;
	fallbackURLArgs?: FallbackURLArgs;
}) => {
	const { setIsLoading, fallbackURLArgs } = props;
	const [hasRedirected, setHasRedirected] = useState(false);
	const router = useRouter();
	useEffect(() => {
		if (router.isReady && !hasRedirected) {
			const nonce = router.query['state'];
			redirect(nonce, fallbackURLArgs);
			setHasRedirected(true);
			setIsLoading && setIsLoading(false);
		}
	}, [router.isReady, hasRedirected]);
};

export default useUrlState;
