import Dayjs from 'dayjs';
import { Fragments, Types } from 'common';

const getEarlyBirdExpirationDate = (
	priceList?: Types.PriceListR | null,
	content?: Fragments.ContentWithProgramSchedulesFieldsFragment
) => {
	if (!priceList || !content) {
		return null;
	}
	return Dayjs(content.startDateTime).subtract(priceList.earlyBirdExpirationWeeks, 'week');
};

const getNormalExpirationDate = (
	priceList?: Types.PriceListR | null,
	content?: Fragments.ContentWithProgramSchedulesFieldsFragment
) => {
	if (!priceList || !content) {
		return null;
	}
	return Dayjs(content.startDateTime).subtract(priceList.normalPriceExpirationDays, 'day');
};

export default {
	getEarlyBirdExpirationDate,
	getNormalExpirationDate
};
