import React from 'react';
import { NoColorLogo } from './index';

import logoFomf from './logo-fomf.svg';
import logoFomfUK from './logo-fomf-uk.png';

type Attributes = {
	fill?: string;
	className?: string;
};

type Props = {
	locale: Locale;
	type?: 'large' | 'compact';
} & Attributes;

const Logo = (props: Props) => {
	const { locale, type = 'large', ...rest } = props;

	let LogoImage;

	if (type === 'compact' && locale !== 'en-GB') {
		return <NoColorLogo {...rest} />;
	}

	switch (locale) {
		case 'en-GB':
			LogoImage = (props: Attributes) => <img src={logoFomfUK} {...props} />;
			break;
		case 'fr-CH':
		case 'de-AT':
		case 'de-DE':
		case 'de-CH':
		default:
			LogoImage = logoFomf;
			break;
	}

	return <LogoImage {...rest} />;
};

export default Logo;
