import { createApolloClient, Queries } from 'common';

const getContentById = async (contentId: string, cookie?: string) => {
	const client = createApolloClient({ batchHttpLinkOptions: { headers: { cookie } } });
	const { data, error = null } = await client.query<Queries.GetContentByIdQuery | null>({
		query: Queries.GetContentById,
		variables: { contentId }
	});
	const content = data?.content;
	return { content, error };
};

export default getContentById;
