import React from 'react';
import { Box } from '@mui/material';
import { useUserContext } from '../../../contexts';
import { useChangeURL } from '../../../hooks';
import setStateInCookies from '../../../cookies/setStateInCookies';
import * as hydra from '../../../hydra/hydra';
import { Avatar, Icon, Typography } from 'components';
import { useToolbarHeaderStyles } from './Header.mobile.styles';
import { useIntl } from 'react-intl';
import { MEDIATHEK_URLS } from '../../../urls';
import NextLink from '../../NextLink';
import classnames from 'classnames';
import { getCountryCode, isFeatureEnabled } from '../../../utils';

const UserMenu = ({ onHeaderClick }: ToolbarHeaderProps) => {
	const userCtx = useUserContext();
	const { isLoggedIn, user } = userCtx;
	const { locale } = useIntl();
	const classes = useToolbarHeaderStyles();

	const changeURL = useChangeURL();

	const handleLogin = () => {
		const nonce = setStateInCookies({});
		changeURL(hydra.getAuthURL({ nonce, locale }));
	};

	return isLoggedIn && (user?.firstName || user?.email) ? (
		<div className={classes.userMenuContainer} onClick={onHeaderClick}>
			<Avatar
				className={classes.avatar}
				email={user?.email}
				firstName={user?.firstName}
				lastName={user?.lastName}
			/>
			<div className={classes.rightContainer}>
				<Typography variant="textXSmall" className={classes.subtitle}>
					{user.firstName ?? ''} {user.lastName ?? ''}
				</Typography>
				<Icon
					icon="caretRight"
					className={classnames(classes.caretRight, classes.primary)}
				/>
			</div>
		</div>
	) : (
		<div className={classes.userMenuContainer} onClick={handleLogin}>
			<Box className={classes.avatar}>
				<Icon icon="login" className={classes.loginIcon} />
			</Box>
			<div className={classes.rightContainer}>
				<div className={classes.signIn}>
					<Typography
						variant="textSmall"
						className={classes.title}
						color="secondary"
						localeId="common.header.login-sign-up"
					/>
					<Icon icon="arrowRightType2" className={classes.signInIcon} />
				</div>
			</div>
		</div>
	);
};

type ToolbarHeaderProps = {
	onHeaderClick: () => void;
};

export const ToolbarHeader = (props: ToolbarHeaderProps) => {
	const { locale } = useIntl();
	const isTopicsPageEnabled = isFeatureEnabled('topicSelector', getCountryCode(locale as Locale));
	const classes = useToolbarHeaderStyles();
	const { formatMessage } = useIntl();

	return (
		<Box className={classes.toolbarContainer}>
			<UserMenu {...props} />
			{isTopicsPageEnabled && (
				<NextLink href={MEDIATHEK_URLS.topics} className={classes.topicsLink}>
					<Icon icon="listBullets" className={classes.topicsIcon} />
					{formatMessage({
						id: 'common.header.topics'
					})}
				</NextLink>
			)}
		</Box>
	);
};
