import React from 'react';
import { Box } from '@mui/material';
import { Typography } from 'components';
import classNames from 'classnames';

import { useStyles } from './TenantHeader.desktop.styles';
import { TenantConfig } from '../../../../../catalog/src/utils/tenant';
import LocaleSwitch from '../LocaleSwitch';
import HeaderUserNavBar from '../../HeaderUserNavBar/HeaderUserNavBar';
import { HeaderUserNavBarProps } from '../../HeaderUserNavBar/HeaderUserNavBar.types';
import { USER_URLS } from '../../../urls';

type Props = {
	tenantConfig: TenantConfig;
	userNavBarProps: HeaderUserNavBarProps;
};

export const TenantHeaderDesktop = (props: Props) => {
	const { tenantConfig, userNavBarProps } = props;
	const classes = useStyles();
	const { companyPage } = tenantConfig;
	const companyName = companyPage?.slug?.toUpperCase() || '';
	const tenant = companyPage?.slug || '';
	return (
		<Box className={classes.wrapper}>
			<Box width="100%">
				<Box className={classes.firstRow}>
					<Box className={classes.navLinks}>
						<Box className={classes.logoContainer}>
							<img
								className={classes.logo}
								src="https://www.fomf.de/favicon.svg"
								alt="FOMF"
							/>
							<Typography
								variant="body1"
								localeId="common.components.header.tenantHeader.fomf"
							/>
						</Box>
						/
						<Typography
							variant="body1"
							className={classNames(classes.navItem, classes.partnerforum)}
							localeId="common.components.header.tenantHeader.partnerforum"
						/>
						/
						<Typography
							variant="body1"
							className={classes.navItem}
							localeId="common.components.header.tenantHeader.tenant"
							localeValues={{ companyName }}
						/>
					</Box>
					<Box>
						<HeaderUserNavBar
							{...userNavBarProps}
							loginActionLocaleId="common.login.tenant-title"
							loginHref={USER_URLS.loginWithQuery({
								tenant
							})}
							registrationHref={USER_URLS.registration({
								tenant
							})}
						/>
					</Box>
				</Box>
				<Box className={classes.localesContainer}>
					<LocaleSwitch />
				</Box>
			</Box>
		</Box>
	);
};
