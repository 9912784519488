import getCurrentUrlWithoutQuery from './getCurrentUrlWithoutQuery';

/**
 * @param replacementQuery - query params string without initial `?`
 */
const removeQueryParams = (replacementQuery?: string) => {
	if (typeof window === 'undefined') {
		console.error('removeQueryParams should not be used on server-side');
		return;
	}
	const currentUrlWithoutQuery = getCurrentUrlWithoutQuery(replacementQuery);
	window.history.replaceState(undefined, '', currentUrlWithoutQuery);
};

export default removeQueryParams;
