import React, { PropsWithChildren } from 'react';
import {
	Footer,
	useHeaderHeight,
	PayNotPaidMembershipHeaderBanner,
	useIsDesktop,
	HeaderDesktop,
	HeaderMobile
} from 'common';
import { Box, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from 'components';
import { TenantConfig } from '../utils/tenant';

const useStyles = makeStyles<ThemeTypes.Theme, { headerHeight: number }>((theme) => ({
	paper: {
		backgroundColor: theme.palette.common.white,
		margin: 0,
		position: 'sticky',
		top: 0,
		right: 0,
		left: 0,
		zIndex: 1000,
		borderRadius: 0
	},
	content: ({ headerHeight }) => ({
		display: 'flex',
		flexDirection: 'column',
		marginTop: headerHeight,
		minHeight: `calc(100vh - ${headerHeight}px)`
	})
}));

type DefaultLayoutProps = PropsWithChildren & {
	isMobile?: boolean | undefined | null;
	tenantConfig?: TenantConfig | null;
};

export const DefaultLayout = ({ children, isMobile }: DefaultLayoutProps) => {
	const headerHeight = useHeaderHeight({ defaultMatches: isMobile === false });
	const classes = useStyles({ headerHeight });
	const isDesktop = useIsDesktop({ defaultMatches: isMobile !== true });

	return (
		<div>
			<Paper className={classes.paper}>
				{isDesktop ? <HeaderDesktop /> : <HeaderMobile />}
			</Paper>
			<Box className={classes.content}>
				<PayNotPaidMembershipHeaderBanner isMobile={isMobile ?? true} />
				{children}
				<Footer />
			</Box>
		</div>
	);
};
