import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { DEFAULT_LOCALE, LOCALE_TO_COUNTRY_CODE, LOCALE_TO_DOMAIN } from '../constants';
import { getDomainFromLocation } from '../utils';

interface LocaleData {
	locale: Locale;
	domain: Domain;
	countryCode: CountryCode;
}

/**
 * @deprecated This function is deprecated because it uses an unsafe method to get the domain on the client side. Use LOCALE_TO_DOMAIN mapping instead. In the future, we should add locale, domain, and countryCode to the page context.
 */
export default function useLocaleData(): LocaleData {
	const { locale } = useRouter();
	const ensuredLocale = (locale || DEFAULT_LOCALE) as Locale;
	const [domain, setDomain] = useState<Domain>(LOCALE_TO_DOMAIN[ensuredLocale]);
	const countryCode = LOCALE_TO_COUNTRY_CODE[ensuredLocale];

	// this will only run in the client
	useEffect(() => {
		setDomain(getDomainFromLocation());
	}, []);

	return {
		locale: ensuredLocale,
		domain,
		countryCode
	};
}
